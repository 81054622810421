<template>
	<v-container fluid class="fill-height pa-0">
		<v-row v-if="this.$vuetify.breakpoint.mdAndDown" align="center">
			<v-col cols="12">
				<!-- MOBILE MENU -->
				<v-toolbar flat class="grey lighten-4">
					<v-menu offset-y bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn plain v-bind="attrs" v-on="on">
								My Account<v-icon>mdi-menu-down</v-icon>
							</v-btn>
						</template>
						<v-list>
							<v-list-item-group>
								<v-list-item
									v-for="item in sidebarItems"
									:key="item.id"
									:to="{ name: item.routeName }"
									color="primary"
								>
									<v-list-item-subtitle>
										{{ item.label }}
									</v-list-item-subtitle>
								</v-list-item>
							</v-list-item-group>
						</v-list>
					</v-menu>
				</v-toolbar>
			</v-col>
		</v-row>
		<v-row no-gutters class="fill-height">
			<!-- SIDEBAR -->
			<v-col v-if="this.$vuetify.breakpoint.lgAndUp" lg="3">
				<v-card class="fill-height" color="grey lighten-4" flat>
					<v-card-title>My Account</v-card-title>
					<v-card-text>
						<v-list class="transparent">
							<v-list-item-group>
								<v-list-item
									v-for="item in sidebarItems"
									:key="item.id"
									:to="{ name: item.routeName }"
								>
									<v-list-item-content>
										<v-list-item-title
											v-text="item.label"
										></v-list-item-title>
									</v-list-item-content>
								</v-list-item>
							</v-list-item-group>
						</v-list>
					</v-card-text>
				</v-card>
			</v-col>
			<!-- VIEWS -->
			<v-col lg="9">
				<v-sheet outlined shaped class="pa-1">
					<router-view></router-view>
				</v-sheet>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
	export default {
		name: "Account",
		data() {
			return {
				loading: false,
				sidebarItems: [
					{ id: 0, label: "My Profile", routeName: "MyProfile" },
					{ id: 1, label: "My Quotes", routeName: "MyQuotes" },
				],
			};
		},
	};
</script>
