import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"fill-height pa-0",attrs:{"fluid":""}},[(this.$vuetify.breakpoint.mdAndDown)?_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VToolbar,{staticClass:"grey lighten-4",attrs:{"flat":""}},[_c(VMenu,{attrs:{"offset-y":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"plain":""}},'v-btn',attrs,false),on),[_vm._v(" My Account"),_c(VIcon,[_vm._v("mdi-menu-down")])],1)]}}],null,false,1790023785)},[_c(VList,[_c(VListItemGroup,_vm._l((_vm.sidebarItems),function(item){return _c(VListItem,{key:item.id,attrs:{"to":{ name: item.routeName },"color":"primary"}},[_c(VListItemSubtitle,[_vm._v(" "+_vm._s(item.label)+" ")])],1)}),1)],1)],1)],1)],1)],1):_vm._e(),_c(VRow,{staticClass:"fill-height",attrs:{"no-gutters":""}},[(this.$vuetify.breakpoint.lgAndUp)?_c(VCol,{attrs:{"lg":"3"}},[_c(VCard,{staticClass:"fill-height",attrs:{"color":"grey lighten-4","flat":""}},[_c(VCardTitle,[_vm._v("My Account")]),_c(VCardText,[_c(VList,{staticClass:"transparent"},[_c(VListItemGroup,_vm._l((_vm.sidebarItems),function(item){return _c(VListItem,{key:item.id,attrs:{"to":{ name: item.routeName }}},[_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(item.label)}})],1)],1)}),1)],1)],1)],1)],1):_vm._e(),_c(VCol,{attrs:{"lg":"9"}},[_c(VSheet,{staticClass:"pa-1",attrs:{"outlined":"","shaped":""}},[_c('router-view')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }